  .ant-form-item {
    margin-bottom: 10px;
  }

  .center-title {
    display: none;
  }

  .ant-select .first-child {
    border: none;
  }

  @media print {
    @page {
      size: letter portrait;
      margin: 0.5in; 
    }

    body {
      zoom: 80%;
    }

    .center-title {
      text-align: center;
      display: block;
    }

    .ant-divider,  /* Selects all Divider components */
    .ant-btn {     /* Selects all Button components */
      display: none !important; /* Hides the components */
    }

    .breakdown-title {
      display: none;
    }

    .ant-input {
      color: blue;
    }

    .ant-select-selection-item {
      color: blue;
    }

    .ant-input-number .ant-input-number-input {
      color: blue;
    }

    /*
    .ant-input-number {
      border: none;
    }
    */

    .ant-form-item {
      margin-bottom: 5px;
    }

    .ant-select .ant-select-arrow {
      display: none;
    }

    /*
    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
      border: none;
    }
    */
  }