.bg-image {
    color: white;
    background: #37387a;
    background-image: url('../../images/space.jpg');
    min-height: 50vh;
    padding-top: 20vh;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
}
  
.bg-image h1{
    font-size: 3rem;
    margin: 0 0 1rem;
}

.bg-image h2{
    font-weight: 300;
    font-size: 1.5rem;
    margin: 0 0 1rem;
}

.bg-image a{
    color: #ffff;
}
