* {
    box-sizing: border-box;
}

.main-content {
    margin: 4.5rem 0rem 2rem 0rem;
}

.content-container {
    margin: 2.5rem auto 2.5rem auto;
    max-width: 1126px;
    min-height: 50vh;
    padding: 10px;
}

/*Contact us*/
tr, td {
	vertical-align: baseline;
}

.row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 480px;
}

.col-left {
		text-align: center;
		padding:0;
		margin-left: 20px;
		margin-right: 20px;
        box-sizing:border-box;
        -webkit-box-sizing:border-box;
        -moz-box-sizing:border-box;
        -webkit-font-smoothing:antialiased;
        -moz-font-smoothing:antialiased;
        -o-font-smoothing:antialiased;
        font-smoothing:antialiased;
        text-rendering:optimizeLegibility;

        width: 42%;
        min-width: 250px;
        line-height: 20px;
        position: relative;
}
	
.col-right {
		padding:0;
		margin-left: 20px;
		margin-right: 20px;
        width: 40%;
        min-width: 250px;
        line-height: 20px;
        position: relative;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact textarea, #contact button[type="submit"] { 
    font:400 12px/16px "Open Sans", Helvetica, Arial, sans-serif; 
}

#contact, #details {
	padding: 10px;
}

fieldset {
	border: medium none !important;
	margin: 0 0 10px;
	min-width: 100%;
	padding: 0;
	width: 100%;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea {
    width:90%;
	border:1px solid #CCC;
	background:#FFF;
	margin:0 0 5px;
	padding:10px;
}

#contact input[type="text"]:hover, #contact input[type="email"]:hover, #contact input[type="tel"]:hover, #contact input[type="url"]:hover, #contact textarea:hover {
	-webkit-transition:border-color 0.3s ease-in-out;
	-moz-transition:border-color 0.3s ease-in-out;
	transition:border-color 0.3s ease-in-out;
	border:1px solid #AAA;
}

#contact textarea {
	height:100px;
	max-width:100%;
    resize:none;
}

#contact button[type="submit"] {
	cursor:pointer;
	width:100%;
	border:none;
	background:#37387a;;
	color:#FFF;
	margin:0 0 5px;
	padding:10px;
	font-size:15px;
}

#contact button[type="submit"]:hover {
	background:#22234b;
	-webkit-transition:background 0.3s ease-in-out;
	-moz-transition:background 0.3s ease-in-out;
	transition:background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active { box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.5); }

#contact input:focus, #contact textarea:focus {
	outline:0;
	border:1px solid #999;
}
::-webkit-input-placeholder {
 color:#888;
}
:-moz-placeholder {
 color:#888;
}
::-moz-placeholder {
 color:#888;
}
:-ms-input-placeholder {
 color:#888;
}